import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#71C238',
    },
  },
  typography: {
    fontFamily: 'Inter, Poppins, Arial, sans-serif',
    fontSize: 16,
    htmlFontSize: 16,
    body1: {
    },
    body2: {
      fontSize: "1.25rem",
      fontWeight: 400,
    },
    body3: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    h1: {
      color: 'white',
      fontWeight: 700,
      fontSize: { xs: "2rem", md: "3rem" },
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.75rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.25rem",
    },
    subtitle1: {
      fontSize: "1.25rem",
      color: '#BDBDBD'
    }
  },
});

export default theme;