import * as React from 'react';
import { Stack } from '@mui/material';
import SourceNonprofits from './Features/SourceNonprofits';
import DiscoverGrants from './Features/DiscoverGrants';
import StandardApp from './Features/StandardApp';

const About = () => {

    return (
        <Stack className="Features" id="Features">
            <SourceNonprofits />
            <DiscoverGrants />
            <StandardApp />
        </Stack>
    );
};
export default About;