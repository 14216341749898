import * as React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import Forest from '../../images/Forest.jpeg';


const DiscoverGrants = () => {
    return (
        <Grid
            container
            className="DiscoverGrants"
            sx={{
                backgroundColor: '#F2F2F2'
            }}
            p={4}
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
        >
            <Grid
                item
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Stack spacing={2} justifyContent='center'>
                    <Typography variant='h4'>
                        Discover Grants
                    </Typography>
                    <Box
                        sx={{
                            maxWidth: { xs: 250, md: 400 },
                        }}
                    >
                        <Typography variant='body1'>
                            Nonprofits can discover grants that they qualify for in a single, centralized database.
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
            <Grid
                item
                xs={6}
                textAlign='center'
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    sx={{
                        height: { xs: 250, md: 600 },
                        width: { xs: 250, md: 600 },
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img src={Forest} alt="Forest" style={{ maxWidth: "100%", maxHeight: "100%", }} />
                </Box>
            </Grid>
        </Grid>
    );
};
export default DiscoverGrants;