import * as React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import StickyNotes from '../../images/StickyNotes.jpeg';


const SourceNonprofits = () => {

    return (
        <Grid
            container
            className="SourceNonprofits"
            sx={{
                backgroundColor: 'white'
            }}
            p={4}
            spacing={2}
            direction={{ xs: 'column-reverse', md: 'row' }}
        >
            <Grid
                item
                xs={6}
                textAlign='center'
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    sx={{
                        height: { xs: 250, md: 600 },
                        width: { xs: 250, md: 600 },
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img src={StickyNotes} alt="Sticky Notes" style={{ maxWidth: "100%", maxHeight: "100%", }} />
                </Box>
            </Grid>
            <Grid
                item
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Stack spacing={2} justifyContent='center'>
                    <Typography variant='h4'>
                        Source Nonprofits
                    </Typography>
                    <Box
                        sx={{
                            maxWidth: { xs: 250, md: 400 },
                        }}
                    >
                        <Typography variant='body1'>
                            Philanthropists can seamlessly find nonprofit organizations to support that align with their values and missions.
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};
export default SourceNonprofits;