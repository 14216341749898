import Navbar from "./components/Navbar";
import About from "./components/About";
import { Toolbar } from '@mui/material';
import Features from './components/Features';
import Contact from "./components/Contact";

function App() {
  return (
    <>
      <Navbar />
      <Toolbar />
      <About />
      <Features />
      <Contact />
    </>
  );
}

export default App;
