import * as React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import Typing from '../../images/Typing.jpeg';


const StandardApp = () => {

    return (
        <Grid
            container
            className="StandardApp"
            sx={{
                backgroundColor: 'white',
                p: { xs: 2, md: 4 }
            }}
            spacing={2}
            direction={{ xs: 'column-reverse', md: 'row' }}
        >
            <Grid
                item
                xs={6}
                textAlign='center'
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    sx={{
                        height: { xs: 250, md: 600 },
                        width: { xs: 250, md: 600 },
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img src={Typing} alt="Typing" style={{ maxWidth: "100%", maxHeight: "100%", }} />
                </Box>
            </Grid>
            <Grid
                item
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Stack spacing={2} display='flex' justifyContent='center'>
                    <Typography variant='h4'
                        sx={{
                            maxWidth: { xs: 250, md: 400 },
                        }}
                    >
                        Standardized Application
                    </Typography>
                    <Box
                        sx={{
                            maxWidth: { xs: 250, md: 400 },
                        }}
                    >
                        <Typography variant='body1'>
                            Nonprofits complete a standardized application, enabling them to search for more grants. Philanthropists find a larger pool of candidate organizations to support.
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};
export default StandardApp;